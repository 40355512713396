import React from 'react';

function Education() {
  return (
    <section className="Education">
      <h3>Ausbildung</h3>
      <ul>
        <li><strong>Bachelor of Science in Informatik</strong> - Hochschule Trier (2008 - 2012)</li>
      </ul>
    </section>
  );
}

export default Education;
import React from 'react';
import Biography from './Biography';
import Experience from './Experience';
import Education from './Education';
import Skills from './Skills';
import ContactInfo from './ContactInfo';
import "./LandingPage.css"

function LandingPage() {
  return (
    <div className="landing-page">
      <header>
        <h1>Willkommen auf der persönlichen Webseite von Max Mustermann</h1>
        <img src="./duck.jpg" alt="Max Mustermann" />
      </header>
      <Biography/>
      <Experience />
      <Education />
      <Skills />
      <ContactInfo />
    </div>
  );
}

export default LandingPage;
import React, { useState } from 'react';
import './Navbar.css';  // Verlinke dein CSS-Stylesheet
import Logo from './Logo';
import { Link } from 'react-router-dom';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false); // Zustand für das Burger-Menü

  // Funktion zum Umschalten des Menüs
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      {/* Linkes Icon */}
      <div className="navbar-left">
        <Link to="/" aria-label="Logo">
          <Logo />
        </Link>
      </div>

      {/* Mittleres Suchfeld 
      <div className="navbar-center">
        <input type="text" placeholder="Suche..." className="navbar-search" />
      </div>
      */}

      {/* Burger-Menü-Symbol (nur auf mobilen Geräten sichtbar) */}
      <button className="burger-menu" onClick={toggleMenu}>
        <div className="burger-bar"></div>
        <div className="burger-bar"></div>
        <div className="burger-bar"></div>
      </button>

      {/* Rechte Navigationslinks (bei mobilen Geräten versteckt) */}
      <div className={`navbar-right ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="/projects">Projekte</a></li>
          <li><a href="/blog">Blog</a></li>
          <li><a href="/contact">Kontakt</a></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
import React from 'react';

function Experience() {
  return (
    <section className="Experience">
      <h3>Berufserfahrung</h3>
      <ul>
        <li><strong>Senior Software Engineer</strong> bei TechSolutions (2020 - Heute)</li>
        <p>
          Leitender Entwickler im Frontend-Team, Schwerpunkt auf benutzerfreundlichen Oberflächen mit React und Redux. 
          Führung eines kleinen Entwicklerteams und Koordination mit Design- und Produktteams zur Lieferung hochwertiger Software.
        </p>
        <li><strong>Softwareentwickler</strong> bei WebInnovators (2015 - 2020)</li>
        <p>
          Entwicklung und Wartung verschiedener Webanwendungen mit JavaScript-Frameworks wie Angular und React. 
          Implementierung mehrerer Funktionen für stark frequentierte Webseiten.
        </p>
        <li><strong>Junior Entwickler</strong> bei StartUp Inc. (2012 - 2015)</li>
        <p>
          Grundlegende Kenntnisse in Full-Stack-Webentwicklung erworben und an mehreren Kundenprojekten in einem agilen Umfeld gearbeitet.
        </p>
      </ul>
    </section>
  );
}

export default Experience;
import React from 'react';

function Biography() {
  return (
    <section className="Biography">
      <h2>Über mich</h2>
      <p>
        Hallo! Ich bin Max Mustermann, ein leidenschaftlicher Softwareentwickler aus Berlin, Deutschland. 
        Ich habe über 42 Jahre Erfahrung in der Webentwicklung und bin spezialisiert auf React, 
        JavaScript und cloudbasierte Anwendungen. Während meiner Karriere habe ich an verschiedenen Projekten 
        für Startups und große Unternehmen gearbeitet und dabei geholfen, skalierbare und effiziente Webanwendungen zu erstellen.
      </p>
      <p>
        Ich liebe es, neue Technologien zu lernen und mein Wissen mit der Community durch Bloggen und Open-Source-Beiträge zu teilen. 
        Wenn ich nicht programmiere, findet man mich oft beim Wandern in den Bergen oder beim Erkunden neuer Städte.
      </p>
    </section>
  );
}

export default Biography;
import React from 'react';

function Skills() {
  return (
    <section className="Skills">
      <h3>Fähigkeiten</h3>
      <ul>
        <li>React, JavaScript (ES6+), TypeScript</li>
        <li>HTML, CSS, SASS</li>
        <li>Node.js, Express.js</li>
        <li>REST APIs, GraphQL</li>
        <li>CI/CD, AWS, Docker</li>
      </ul>
    </section>
  );
}

export default Skills;

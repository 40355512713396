import React from 'react';

function ProjectsPage() {
  const projects = [
    { title: 'Project 1', description: 'Description of project 1', link: '/project1' },
    { title: 'Project 2', description: 'Description of project 2', link: '/project2' },
    // Add more projects
  ];

  return (
    <div className="projects-page">
      <h1>My Projects</h1>
      <ul>
        {projects.map((project, index) => (
          <li key={index}>
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <a href={project.link}>Learn More</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ProjectsPage;
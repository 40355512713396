import React from 'react';

function ContactInfo() {
  return (
    <section className="Contact-info">
      <h3>Kontaktinformationen</h3>
      <p>Email: max.mustermann@example.com</p>
      <p>Standort: Berlin, Deutschland</p>
    </section>
  );
}

export default ContactInfo;
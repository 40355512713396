import React, { useState, useEffect } from 'react';

function BlogPage() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    // Fetch blog articles from an API or a local source
    const fetchedArticles = [
      { title: 'Post 1', content: 'Content of blog post 1' },
      { title: 'Post 2', content: 'Content of blog post 2' },
      // Add more articles
    ];
    setArticles(fetchedArticles);
  }, []);

  return (
    <div className="blog-page">
      <h1>Blog</h1>
      {articles.map((article, index) => (
        <div key={index} className="blog-post">
          <h2>{article.title}</h2>
          <p>{article.content}</p>
        </div>
      ))}
    </div>
  );
}

export default BlogPage;
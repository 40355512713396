import React from 'react';

function Logo() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Erstes Dreieck (nach oben zeigend, Spitze überragt nach oben) */}
      <polygon
        points="25,75 50,15 75,75"  // Die Spitze ragt um 10% nach oben heraus (15 statt 25)
        fill="none"
        stroke="#61dafb"    // Randfarbe
        strokeWidth="4"     // Dicke des Randes
      />

      {/* Zweites Dreieck (nach unten zeigend, Spitze überragt nach unten) */}
      <polygon
        points="25,25 50,85 75,25"  // Die Spitze ragt um 10% nach unten heraus (85 statt 75)
        fill="none"
        stroke="#61dafb"    // Randfarbe
        strokeWidth="4"     // Dicke des Randes
      />

      {/* Kreis in der Mitte */}
      <circle
        cx="50"             // X-Koordinate des Kreises
        cy="50"             // Y-Koordinate des Kreises
        r="3"              // Radius des Kreises
        fill="none"
        stroke="#61dafb"    // Randfarbe
        strokeWidth="4"     // Dicke des Randes
      />
    </svg>
  );
}

export default Logo;
